import React, { Component } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "iid/components/layout"
import SEO from "iid/components/seo"
import Slide from "iid/components/slide"
import SetHtml from "iid/components/sethtml"

class IndexPage extends Component {
  componentDidMount = () => {
    let script = document.getElementById("hubscript")
    if (script === null) {
      script = document.createElement("script")
      script.id = "hubscript"
      script.src = "//js.hsforms.net/forms/shell.js"
      document.body.appendChild(script)
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "4825986",
            formId: "e57e10d2-c094-4cc8-b1c1-80e7489c14a8",
            target: "#hubspotForm",
          })
        }
      })
    } else {
      // still need to create the form, but we don't have to reload the script
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4825986",
          formId: "e57e10d2-c094-4cc8-b1c1-80e7489c14a8",
          target: "#hubspotForm",
        })
      }
    }
    // Adjust padding before anchor so we don't cover it with the navbar
    document.getElementById("contactme").style.paddingTop =
      document.body.style.paddingTop
  }

  componentDidUpdate = () => {}

  render = () => {
    const overview = this.props.data.overview.html
    const steveImage = this.props.data.steveImage
    return (
      <Layout>
        <SEO title="Home" />
        <div
          className="container-fluid slide-height"
          style={{ marginTop: `200px` }}
        >
          <div className="row">
            <div className="col-12 mx-auto my-auto text-light ">
              <section>
                <h1 className="text-center display-1">Steve Hawley, PhD</h1>
                <h2 className="text-center">Data Scientist</h2>
              </section>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-white">
          <Slide>
            <div className="py-3 mx-auto w-50">
              <GatsbyImage
                image={steveImage.childImageSharp.gatsbyImageData}
                alt="Stephen Hawley, PhD"
                className="headshot mx-auto"
              />
            </div>
            <SetHtml content={overview} className="p-3" />
          </Slide>
        </div>
        <div className="container-fluid">
          <div className="row  pt-3 bg-light">
            <div className="col-lg-4 col-sm-3"></div>
            <div className="col-lg-4 col-sm-7 my-auto">
              <h1 className="text-left" id="contactme">
                For Inquiries
              </h1>
              <p>
                Want to get in touch? I've got you covered. Just use this handy
                form to send me a quick message and I'll get back to you real
                soon.
              </p>
              <div id="hubspotForm" style={{ height: "500px" }}></div>
            </div>
            <div className="col-lg-4 col-sm-2"></div>
          </div>
        </div>
        <div className="bg-dark"></div>
      </Layout>
    )
  }
}

export default IndexPage
export const query = graphql`
  {
    overview: markdownRemark(frontmatter: { slug: { eq: "steve-overview" } }) {
      html
    }
    steveImage: file(relativePath: { eq: "steve.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
