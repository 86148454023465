// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Slide = ({ children }) => (
  <div className="row slide-height pt-3">
    <div className="col-lg-3 col-md-2 d-none d-md-block"></div>
    <div className="col-lg-6 col-sm-12 col-md-8">{children}</div>
    <div className="col-lg-3 col-md-2 d-none d-md-block"></div>
  </div>
)

export default Slide
